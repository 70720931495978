var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order_list" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入订单号", clearable: "" },
                    model: {
                      value: _vm.searchForm.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                      },
                      expression: "searchForm.orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "goodsName", $$v)
                      },
                      expression: "searchForm.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名", clearable: "" },
                    model: {
                      value: _vm.searchForm.receivingName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "receivingName", $$v)
                      },
                      expression: "searchForm.receivingName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "续租单" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.is_renewal,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "is_renewal", $$v)
                        },
                        expression: "searchForm.is_renewal",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: { label: "全部", value: "0" },
                      }),
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "续租单", value: "1" },
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "常规单", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否支付" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.is_pay,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "is_pay", $$v)
                        },
                        expression: "searchForm.is_pay",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: { label: "全部", value: "0" },
                      }),
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "已支付", value: "1" },
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "未支付", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.merchantId == 0
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "商户" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.merchantId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "merchantId", $$v)
                              },
                              expression: "searchForm.merchantId",
                            },
                          },
                          _vm._l(_vm.merchantList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.username, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备锁" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.is_lock_phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "is_lock_phone", $$v)
                              },
                              expression: "searchForm.is_lock_phone",
                            },
                          },
                          _vm._l(_vm.isLockList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.order_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "order_type", $$v)
                              },
                              expression: "searchForm.order_type",
                            },
                          },
                          _vm._l(_vm.orderTypeList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "接单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.reciveStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "reciveStatus", $$v)
                              },
                              expression: "searchForm.reciveStatus",
                            },
                          },
                          _vm._l(_vm.reciveStatusList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单来源" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.channel,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "channel", $$v)
                              },
                              expression: "searchForm.channel",
                            },
                          },
                          _vm._l(_vm.channelList, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: {
                                label: item.app_name + " - " + item.name,
                                value: item.key,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分销订单" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.share,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "share", $$v)
                              },
                              expression: "searchForm.share",
                            },
                          },
                          _vm._l(_vm.channelShare, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.name, value: item.key },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分享人手机号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "分销人手机号,只支持完整号码查询",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.shearMobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "shearMobile", $$v)
                            },
                            expression: "searchForm.shearMobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分享人编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "分销人手机号,只支持完整号码查询",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.p_user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "p_user_id", $$v)
                            },
                            expression: "searchForm.p_user_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.merchantId > 0
                ? [
                    _vm.$hasMethod("#allList")
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "跟单员" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "全部", clearable: "" },
                                model: {
                                  value: _vm.searchForm.merchantChildId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "merchantChildId",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.merchantChildId",
                                },
                              },
                              _vm._l(_vm.merchantChildList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号", clearable: "" },
                    model: {
                      value: _vm.searchForm.receivingMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "receivingMobile", $$v)
                      },
                      expression: "searchForm.receivingMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "风控姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入风控姓名", clearable: "" },
                    model: {
                      value: _vm.searchForm.riskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "riskName", $$v)
                      },
                      expression: "searchForm.riskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "风控手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入风控手机号", clearable: "" },
                    model: {
                      value: _vm.searchForm.riskMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "riskMobile", $$v)
                      },
                      expression: "searchForm.riskMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.orderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "orderStatus", $$v)
                        },
                        expression: "searchForm.orderStatus",
                      },
                    },
                    _vm._l(_vm.selectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "390px" },
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["00:00:00", "23:59:59"],
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.orderCreateTime,
                      callback: function ($$v) {
                        _vm.orderCreateTime = $$v
                      },
                      expression: "orderCreateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账单日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.searchForm.leaseDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "leaseDate", $$v)
                      },
                      expression: "searchForm.leaseDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm.$hasMethod("#export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.exportFile()
                            },
                          },
                        },
                        [_vm._v("导出财务")]
                      )
                    : _vm._e(),
                  _vm.$hasMethod("#export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.exportFileRent()
                            },
                          },
                        },
                        [_vm._v("导出租用中")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "flex-1 nav_tabs",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabList, function (item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: {
                  label: item.name + "(" + item.count + ")",
                  name: item.id,
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "list_wrap" },
            [
              _c("div", { staticClass: "flex list_header" }, [
                _c(
                  "p",
                  { staticStyle: { width: "27%", "padding-left": "20px" } },
                  [
                    _vm.merchantId > 0
                      ? _c(
                          "span",
                          { staticClass: "check_all" },
                          [
                            _c("el-checkbox", {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.handleCheckAll },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            }),
                            1
                              ? _c(
                                  "el-dropdown",
                                  {
                                    attrs: { trigger: "click" },
                                    on: { command: _vm.handleCommand },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "border-btn batch-btn",
                                        attrs: { size: "small" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "batch",
                                            size: 10,
                                          },
                                        }),
                                        _vm._v(
                                          "\n                                    批量操作\n                                "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        1
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "auditOrder",
                                                },
                                              },
                                              [_vm._v("跟单分配")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n                        商品信息\n                    "
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "3%" } },
                  [_vm._v("数量")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "10%" } },
                  [_vm._v("租金")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "9%" } },
                  [_vm._v("租期")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "8%" } },
                  [_vm._v("押金")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "15%" } },
                  [_vm._v("实付金额")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "5%" } },
                  [_vm._v("是否买断")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "7%" } },
                  [_vm._v("下期账单")]
                ),
                _c("p", { staticStyle: { width: "16%" } }, [
                  _vm._v("收货人信息"),
                ]),
              ]),
              _vm.loading
                ? _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "loading",
                  })
                : _vm._e(),
              _vm._l(_vm.tableData, function (item) {
                return _c("div", { key: item.id, staticClass: "list_item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-between align-center item_header",
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          ref: "check",
                          refInFor: true,
                          attrs: { label: item.id },
                          on: { change: _vm.handleCheck },
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        [_vm._v(" ")]
                      ),
                      _c(
                        "el-tag",
                        {
                          attrs: { type: _vm.getOrderStatusType(item.status) },
                        },
                        [_vm._v(_vm._s(item.statusName))]
                      ),
                      _c("div", { staticClass: "flex-1 flex align-center" }, [
                        item.is_star > 0
                          ? _c(
                              "p",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "老客户",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/star.png"),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(item.shopname))]),
                        _c("p", [_vm._v("ID：" + _vm._s(item.id))]),
                        item.is_one_rmb_buy
                          ? _c("p", [
                              _c("span", { staticClass: "red-font" }, [
                                _vm._v("1元租"),
                              ]),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v("创建时间：" + _vm._s(item.create_time)),
                        ]),
                        _c("p", [_vm._v("订单号：" + _vm._s(item.trade_no))]),
                        item.is_renewal == 1
                          ? _c(
                              "p",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "续租订单",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/images/renew.png"),
                                        alt: "续租订单",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#ViewSource")
                          ? _c("p", [
                              _vm._v("订单来源：" + _vm._s(item.channel_name)),
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0
                          ? _c("p", [
                              _vm._v(
                                "\n                                下单次数：\n                                "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpListByMobile(
                                        item.mobile,
                                        item.person_order_num
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.person_order_num) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.member_count > 1
                          ? _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpListByRiskName(
                                        item.cert_name
                                      )
                                    },
                                  },
                                },
                                [_vm._v("账号异常")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.other_rent_ing > 0
                          ? _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpListByRiskName(
                                        item.cert_name
                                      )
                                    },
                                  },
                                },
                                [_vm._v("租用异常")]
                              ),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "\n                                快递信息：\n                                "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "express-font pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.checkLogistics(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    item.logistic.delivery_type == 2
                                      ? "上门自取"
                                      : item.logistic.delivery_type == 3
                                      ? "上门安装"
                                      : item.logistic.logistics_no
                                      ? item.logistic.logistics_no +
                                        " (" +
                                        item.logistic.logistics_name +
                                        ")"
                                      : "无"
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.saveLockStatus(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                设备锁:\n                                "
                            ),
                            item.is_lock_phone == 1
                              ? _c("span", { staticClass: "red-font" }, [
                                  _vm._v("有"),
                                ])
                              : _c("span", [_vm._v("无")]),
                          ]
                        ),
                        item.adj_info.used_time
                          ? _c("p", [
                              _vm._v(
                                "下单耗时: " + _vm._s(item.adj_info.used_time)
                              ),
                            ])
                          : _vm._e(),
                        item.adj_info.phone_brand
                          ? _c("p", [
                              _vm._v(
                                "下单机型: " +
                                  _vm._s(item.adj_info.phone_brand) +
                                  " " +
                                  _vm._s(item.adj_info.phone_model)
                              ),
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.is_share
                          ? _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                                是分销单 :" +
                                    _vm._s(item.share_name) +
                                    " - " +
                                    _vm._s(item.share_mobile) +
                                    "\n                                "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderShareList(item)
                                      },
                                    },
                                  },
                                  [_vm._v("分销详情")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.status == 1
                          ? _c("p", [
                              _vm._v("关单时间 : " + _vm._s(item.log_time)),
                            ])
                          : _vm._e(),
                        item.status == 16
                          ? _c("p", [
                              _vm._v("退款时间 : " + _vm._s(item.log_time)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("p"),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex item_row" }, [
                    _c("div", { staticStyle: { width: "27%" } }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.$func.handleOssImg(item.imgurl),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "flex-1",
                              staticStyle: {
                                "padding-left": "10px",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "text-ellipsis title blue-font",
                                },
                                [_vm._v(_vm._s(item.goods_title))]
                              ),
                              _c("p", { staticClass: "meal" }, [
                                _vm._v(
                                  "套餐信息：" + _vm._s(item.setmeal_title)
                                ),
                              ]),
                              item.status == 9
                                ? _c(
                                    "p",
                                    { staticClass: "device" },
                                    [
                                      _vm._v(
                                        "\n                                        修改套餐：\n                                        "
                                      ),
                                      _vm.$hasMethod("#editDevice")
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "border-btn",
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editSetmeal(item)
                                                },
                                              },
                                            },
                                            [_vm._v("修改")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "service" },
                        [
                          _vm._v(
                            "\n                                增值服务：" +
                              _vm._s(item.service.length == 0 ? "无" : "") +
                              "\n                                "
                          ),
                          _vm._l(item.service, function (service) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  staticClass: "service-tag",
                                  attrs: {
                                    type:
                                      service.status == 3 ? "success" : "info",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.serviceInfo(service)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(service.moreservice_name) +
                                      " ￥" +
                                      _vm._s(service.moreservice_price) +
                                      "元"
                                  ),
                                ]
                              ),
                            ]
                          }),
                          [9, 10, 20, 70].indexOf(item.status) > -1
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "border-btn",
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addService(item)
                                    },
                                  },
                                },
                                [_vm._v("编辑服务")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "service" },
                        [
                          _vm._v(
                            "\n                                续租商品id：" +
                              _vm._s(item.relet_goods_id) +
                              "\n\n                                "
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "border-btn",
                              staticStyle: { "margin-left": "10px" },
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.setRelet(item)
                                },
                              },
                            },
                            [_vm._v("绑定续租商品")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "service" }, [
                        _vm._v(
                          "\n                                订单类型：\n                                "
                        ),
                        item.order_type == 2
                          ? _c("span", { staticClass: "red-font" }, [
                              _vm._v("蚂蚁代扣"),
                            ])
                          : _vm._e(),
                        item.order_type == 5
                          ? _c("span", { staticClass: "red-font" }, [
                              _vm._v("签约代扣"),
                            ])
                          : _vm._e(),
                        item.order_type == 1
                          ? _c("span", [_vm._v("芝麻信用")])
                          : _vm._e(),
                      ]),
                      _vm.$hasMethod("#savecost")
                        ? _c("div", { staticClass: "service" }, [
                            _vm._v(
                              "\n                                供应商价格：\n                                "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "red-font",
                                on: {
                                  click: function ($event) {
                                    return _vm.saveCostFun(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.cost_price))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-center color-666 font-16",
                        staticStyle: { width: "3%" },
                      },
                      [_vm._v("x" + _vm._s(item.amount))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "10%" },
                      },
                      [
                        _c("p", { staticClass: "red-font" }, [
                          _vm._v(
                            "￥" +
                              _vm._s(item.rent_total) +
                              "(共" +
                              _vm._s(item.month_total) +
                              "期)"
                          ),
                        ]),
                        _c(
                          "p",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _vm.$hasMethod("#checkLease") &&
                            [1, 3].indexOf(item.order_type) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewLease(item, item.status)
                                      },
                                    },
                                  },
                                  [_vm._v("查看租期")]
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#checkLease") &&
                            item.order_type == 2 &&
                            item.mayi_sign_status == 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewMaYiPayRule(item)
                                      },
                                    },
                                  },
                                  [_vm._v("代扣详情")]
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#checkLease") &&
                            item.order_type == 5
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewLeaseAgree(
                                          item,
                                          item.status
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看租期")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "color-666 font-12 flex flex-center",
                        staticStyle: { width: "9%" },
                      },
                      [
                        _vm._v(
                          _vm._s(item.begin_time) +
                            "至" +
                            _vm._s(item.end_time) +
                            "(共" +
                            _vm._s(item.month_total) +
                            "期)"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "8%" },
                      },
                      [
                        item.rights_auth_no
                          ? _c("p", { staticClass: "red-font" }, [
                              _vm._v("总押金:￥" + _vm._s(item.foregift_all)),
                            ])
                          : _vm._e(),
                        item.rights_auth_no
                          ? _c("p", { staticClass: "red-font" }, [
                              _vm._v("剩余:￥" + _vm._s(item.foregift)),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "color-999 font-12" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                !item.rights_auth_no
                                  ? "未授权免押"
                                  : item.foregift_deduct == 0
                                  ? "押金冻结"
                                  : item.foregift > 0
                                  ? "部分免押"
                                  : "押金全免"
                              ) +
                              "\n                            "
                          ),
                        ]),
                        item.foregift_deduct > 0
                          ? _c(
                              "p",
                              {
                                staticClass: "color-999 font-12",
                                staticStyle: {
                                  "text-decoration": "line-through",
                                },
                              },
                              [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      parseFloat(
                                        parseFloat(item.foregift) +
                                          parseFloat(item.foregift_deduct)
                                      ).toFixed(2)
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "15%" },
                      },
                      [
                        _c("p", {}, [
                          _vm._v("买断价: ￥" + _vm._s(item.setmeal_price)),
                        ]),
                        _c("p", { staticClass: "color-999 font-12" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                item.pay_type == 1
                                  ? "智能合同代扣"
                                  : item.pay_type == 2
                                  ? "芝麻信用（" +
                                    (item.rights_auth_no != 0
                                      ? "已授权代扣"
                                      : "未授权代扣") +
                                    "）"
                                  : "花呗"
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _c(
                          "p",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "success", effect: "dark" },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkPaidBill(
                                      item.id,
                                      item.lease.paid_rent
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "已付：￥" +
                                    _vm._s(item.lease.paid_rent) +
                                    "/￥" +
                                    _vm._s(item.rent_total)
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c(
                              "el-tag",
                              { attrs: { type: "warning", effect: "dark" } },
                              [
                                _vm._v(
                                  "已结租金￥" +
                                    _vm._s(item.lease.settle_amount)
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-center font-12 color-666",
                        staticStyle: { width: "5%" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              item.is_buy_out == 1 ? "可买断" : "不可买断"
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "7%" },
                      },
                      [
                        JSON.stringify(item.lease.next) !== "[]"
                          ? [
                              _c("p", { staticClass: "font-12 red-font" }, [
                                _vm._v(
                                  "账单日期: " + _vm._s(item.lease.next.date)
                                ),
                              ]),
                              _c("p", { staticClass: "font-12 red-font" }, [
                                _vm._v(
                                  "账单金额: ￥" +
                                    _vm._s(item.lease.next.amount)
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("div", { staticStyle: { width: "16%" } }, [
                      _c("p", { staticClass: "font-12 color-666" }, [
                        _c("span", [_vm._v(_vm._s(item.realname))]),
                        _c("span", [_vm._v(_vm._s(item.address_mobile))]),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "font-12 color-666",
                          staticStyle: { "word-break": "break-all" },
                        },
                        [
                          _vm._v(
                            _vm._s(item.prov) +
                              _vm._s(item.city) +
                              _vm._s(item.area) +
                              _vm._s(item.address)
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _vm.merchantId == 0 && item.risk_type_receipt > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  staticStyle: { "margin-right": "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRiskArea(item, 2)
                                    },
                                  },
                                },
                                [
                                  item.risk_type_receipt == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "warning",
                                            effect: "dark",
                                          },
                                        },
                                        [_vm._v("中风险")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "danger",
                                            effect: "dark",
                                          },
                                        },
                                        [_vm._v("高风险")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.showAreaDetail(item)
                                },
                              },
                            },
                            [_vm._v("地址详情")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditUserInfo(item)
                                },
                              },
                            },
                            [_vm._v("用户信息修改")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _vm.$hasMethod("#editAddress")
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "border-btn",
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAddress(item)
                                    },
                                  },
                                },
                                [_vm._v("修改地址")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("p", [
                        _c("span", { staticClass: "font-12 color-666" }, [
                          _vm._v("认证姓名: " + _vm._s(item.cert_name)),
                        ]),
                        _vm.merchantId == 0 && item.risk_name_cert > 0
                          ? _c(
                              "span",
                              { staticClass: "pointer" },
                              [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "danger", effect: "dark" } },
                                  [_vm._v("黑名单")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _c("span", { staticClass: "font-12 color-666" }, [
                          _vm._v("认证手机: " + _vm._s(item.mobile)),
                        ]),
                        _c("br"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex item_row" }, [
                    _c(
                      "div",
                      { staticClass: "detail", staticStyle: { width: "57%" } },
                      [
                        _c(
                          "p",
                          { staticClass: "color-666" },
                          [
                            _vm._v(
                              "\n                                认证资料：" +
                                _vm._s(item.cert_name) +
                                "（" +
                                _vm._s(item.cert_no) +
                                "）手机号：" +
                                _vm._s(item.mobile) +
                                "，\n                                "
                            ),
                            item.authentic.status == 1
                              ? _c("span", [_vm._v("人脸识别通过")])
                              : _c("span", { staticClass: "red-font" }, [
                                  _vm._v("人脸识别不通过"),
                                ]),
                            _vm._v(
                              "\n                                性别：" +
                                _vm._s(item.authentic.sex) +
                                " 年龄：" +
                                _vm._s(item.authentic.age) +
                                "\n                                "
                            ),
                            (item.idcard_back || item.idcard_front) &&
                            _vm.$hasMethod("#checkAuthInfo") &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    class: _vm.rzzltype(item.risk_type_risky),
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkAuthInfo(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    认证资料\n                                "
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkAuthInfo(item)
                                      },
                                    },
                                  },
                                  [_vm._v("认证资料")]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "color-666",
                            class: { "red-font": item.is_skip == 1 },
                          },
                          [
                            _vm._v(
                              "\n                                风控信息：" +
                                _vm._s(
                                  item.is_skip == 1
                                    ? "【未风控】商家跳过风控"
                                    : item.risk
                                ) +
                                "\n                                "
                            ),
                            _vm.$hasMethod("#zhimarisk")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkRisk(item, 4)
                                      },
                                    },
                                  },
                                  [_vm._v("芝麻风控")]
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#risk1")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkRisk(item, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("风控一")]
                                )
                              : _vm._e(),
                            !_vm.merchantId
                              ? _c(
                                  "span",
                                  [
                                    _vm.$hasMethod("#risk2")
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "border-btn",
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.checkRisk(item, 2)
                                              },
                                            },
                                          },
                                          [_vm._v("风控二")]
                                        )
                                      : _vm._e(),
                                    _vm.$hasMethod("#riskjh")
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "border-btn",
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.checkRisk(item, 5)
                                              },
                                            },
                                          },
                                          [_vm._v("交互风控")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "border-btn",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkRisk(item, 7)
                                          },
                                        },
                                      },
                                      [_vm._v("浓度算法")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "color-666",
                            class: { "red-font": item.is_skip == 1 },
                          },
                          [
                            _vm._v(
                              "\n                                合同状态：\n                                "
                            ),
                            _vm.$hasMethod("#checkFlow")
                              ? _c(
                                  "el-button",
                                  {
                                    class: {
                                      "border-btn":
                                        (item.esign_flow_id != "" &&
                                          item.esign_sign_status == 2) ||
                                        (item.mayi_flow_id != "" &&
                                          item.mayi_sign_status == 2),
                                    },
                                    attrs: {
                                      size: "mini",
                                      disabled:
                                        (item.esign_flow_id == "" ||
                                          item.esign_sign_status != 2) &&
                                        (item.mayi_flow_id == "" ||
                                          item.mayi_sign_status != 2),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewContract(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          item.esign_sign_status == 2 ||
                                            item.mayi_sign_status == 2
                                            ? "查看合同"
                                            : "未签约"
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "color-666 font-12" }, [
                          _vm._v("用户备注：" + _vm._s(item.remark)),
                        ]),
                        _c(
                          "p",
                          { staticClass: "color-666 font-12" },
                          [
                            _vm._v(
                              "\n                                客服备注：\n                                "
                            ),
                            JSON.stringify(item.orderRemark) !== "[]"
                              ? [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.orderRemark.create_time) +
                                      "，" +
                                      _vm._s(item.orderRemark.username) +
                                      " 添加备注：" +
                                      _vm._s(item.orderRemark.remark) +
                                      "\n                                    "
                                  ),
                                  _vm.$hasMethod("#checkRemark")
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "blue-font pointer",
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewRemark(item.id)
                                            },
                                          },
                                        },
                                        [_vm._v("查看备注记录")]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm.$hasMethod("#addRemark")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addRemark(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("添加备注")]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "p",
                          { staticClass: "color-666" },
                          [
                            JSON.stringify(item.leaseFlow) !== "[]"
                              ? [
                                  _vm._v(
                                    "\n                                    第" +
                                      _vm._s(item.leaseFlow.which_period) +
                                      "期通过支付宝" +
                                      _vm._s(
                                        item.leaseFlow.pay_type == 1
                                          ? "智能合同代扣"
                                          : item.leaseFlow.pay_type == 2
                                          ? "芝麻信用"
                                          : item.leaseFlow.pay_type == 3
                                          ? "花呗"
                                          : "主动支付"
                                      ) +
                                      _vm._s(
                                        item.leaseFlow.status == 1
                                          ? "支付" + item.leaseFlow.rent + "元"
                                          : item.leaseFlow.status == 2
                                          ? "退款" + item.leaseFlow.rent + "元"
                                          : item.leaseFlow.status == 3
                                          ? "买断"
                                          : "未支付"
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              : _vm._e(),
                            _vm.$hasMethod("#checkFlow")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewFlow(item.trade_no)
                                      },
                                    },
                                  },
                                  [_vm._v("流水记录")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticClass: "border-btn",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.emergencyContact(item)
                                  },
                                },
                              },
                              [_vm._v("紧急联系人")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "border-btn",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showOrderInfo(item)
                                  },
                                },
                              },
                              [_vm._v("订单信息")]
                            ),
                          ],
                          2
                        ),
                        [10, 20, 30].includes(item.status) &&
                        _vm.$hasMethod("#riskgz")
                          ? _c(
                              "p",
                              { staticClass: "color-666" },
                              [
                                _vm._v(
                                  "\n                                公正操作：\n                                "
                                ),
                                item.order_equity_status == -1 ||
                                item.order_equity_status == 7 ||
                                item.order_equity_status == 5
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmToEquity(item, 0)
                                          },
                                        },
                                      },
                                      [_vm._v("创建公正信息")]
                                    )
                                  : _vm._e(),
                                item.order_equity_status >= 0 &&
                                item.order_equity_status <= 5
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmToEquity(item, 1)
                                          },
                                        },
                                      },
                                      [_vm._v("取消公正信息")]
                                    )
                                  : _vm._e(),
                                item.order_equity_status > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmToEquity(item, 2)
                                          },
                                        },
                                      },
                                      [_vm._v("查询公正信息")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column",
                        staticStyle: { width: "43%" },
                      },
                      [
                        _vm.merchantId > 0
                          ? _c("div", [
                              _c(
                                "p",
                                { staticClass: "btns flex align-center" },
                                [
                                  _vm._v(
                                    "\n                                    跟单员：\n                                    "
                                  ),
                                  item.merchant_child_id > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getMerchantChildName(
                                              item.merchant_child_id
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.$hasMethod("#allocation")
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "border-btn",
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showAuditOrder(
                                                item.id,
                                                item.merchant_child_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                item.merchant_child_id == 0
                                                  ? "跟单分配"
                                                  : "重新分配"
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  (item.status == 30 || item.status == 20) &&
                                  _vm.$hasMethod("#outboundMethod")
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            class: {
                                              "border-btn": item.is_used == 0,
                                              "border-btn-disabled":
                                                item.is_used == 1,
                                            },
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showAuditOrder2(
                                                  item.id,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  item.is_used == 0
                                                    ? "未出库"
                                                    : "已出库"
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 &&
                        (item.merchant_id ||
                          item.status == 9 ||
                          item.status == 11)
                          ? _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                                接单人：\n                                "
                                ),
                                item.merchant_id > 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getMerchantName(item.merchant_id)
                                        ) + " "
                                      ),
                                    ])
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "border-btn",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDistributionOrder(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("转单")]
                                    ),
                                item.distribution_err_times
                                  ? _c(
                                      "span",
                                      { staticClass: "red-font" },
                                      [
                                        _vm._v(
                                          "\n                                      拒单次数: " +
                                            _vm._s(
                                              item.distribution_err_times
                                            ) +
                                            " 次\n                                    "
                                        ),
                                        item.merchant_id == 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "border-btn",
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.refushDistributionOrder(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("拒单详情")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.orderRefundReason
                          ? _c("p", [
                              _vm._v(
                                "退款原因：" + _vm._s(item.orderRefundReason)
                              ),
                            ])
                          : _vm._e(),
                        item.is_renewal == 1
                          ? _c(
                              "p",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  "\n                                原订单号：\n                                "
                                ),
                                _c("span", { staticClass: "blue-font" }, [
                                  _vm._v(_vm._s(item.parent_order_no)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        item.is_abnormal == 1 &&
                        [41, 42, 43, 44, 45, 49, 60, 65].indexOf(item.status) >
                          -1
                          ? _c(
                              "p",
                              {
                                staticClass: "red-font",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [_vm._v("该订单提前归还")]
                            )
                          : _vm._e(),
                        item.check_refuse_reason && item.status == 43
                          ? _c(
                              "p",
                              {
                                staticClass: "red-font",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [
                                _vm._v(
                                  "审核拒绝原因：" +
                                    _vm._s(item.check_refuse_reason)
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("p", { staticClass: "color-666 red-font" }, [
                          _c("span", { staticClass: "red-font" }, [
                            _vm._v("用户下单位置"),
                          ]),
                          _vm._v(
                            "\n                                ：" +
                              _vm._s(item.al_province) +
                              _vm._s(item.al_city) +
                              _vm._s(item.al_district) +
                              _vm._s(item.al_street) +
                              "\n                                "
                          ),
                          _vm.merchantId == 0 && item.risk_type > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRiskArea(item, 1)
                                    },
                                  },
                                },
                                [
                                  item.risk_type == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "warning",
                                            effect: "dark",
                                          },
                                        },
                                        [_vm._v("中风险")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "danger",
                                            effect: "dark",
                                          },
                                        },
                                        [_vm._v("高风险")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        item.is_renewal == 2
                          ? _c(
                              "p",
                              { staticClass: "btns flex align-center" },
                              [
                                item.status == 10 &&
                                _vm.merchantId == 0 &&
                                item.order_type == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.maYiCancelForSup(item.id)
                                          },
                                        },
                                      },
                                      [_vm._v("主动退款")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId && [9].indexOf(item.status) > -1
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.riskPass(item)
                                            },
                                          },
                                        },
                                        [_vm._v("通过审核")]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.merchantId && [11].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.merRefuseRefund(item)
                                          },
                                        },
                                      },
                                      [_vm._v("拒绝取消申请")]
                                    )
                                  : _vm._e(),
                                item.merchant_id > 0 &&
                                [9].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.riskReject(item)
                                          },
                                        },
                                      },
                                      [_vm._v("拒绝审核")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId &&
                                [11].indexOf(item.status) > -1 &&
                                item.is_merchant == 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.riskReject(item)
                                          },
                                        },
                                      },
                                      [_vm._v("同意申请")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId &&
                                [11].indexOf(item.status) > -1 &&
                                item.is_merchant == 1 &&
                                _vm.$hasMethod("#merPassRefund")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.passRefund(item)
                                          },
                                        },
                                      },
                                      [_vm._v("同意申请")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        item.is_renewal == 1
                          ? _c(
                              "p",
                              { staticClass: "btns flex align-center" },
                              [
                                _vm.merchantId && [9].indexOf(item.status) > -1
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ReletPass(item.id)
                                            },
                                          },
                                        },
                                        [_vm._v("通过续租")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "warning",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ReletReject(item.id)
                                            },
                                          },
                                        },
                                        [_vm._v("拒绝续租")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "btns flex align-center" },
                          [
                            item.status == 10 && _vm.$hasMethod("#delivery")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDelivery(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("订单发货")]
                                )
                              : _vm._e(),
                            item.status == 20 && _vm.$hasMethod("#receipt")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmReceipt(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("确认收货")]
                                )
                              : _vm._e(),
                            [
                              20, 30, 31, 32, 33, 34, 39, 40, 41, 42, 43, 44,
                              45, 49, 50, 51, 52, 56, 57, 58,
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#checkLogistics")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkLogistics(item)
                                      },
                                    },
                                  },
                                  [
                                    item.WLInfo
                                      ? _c("span", [_vm._v("物流记录")])
                                      : _vm._e(),
                                    item.WLInfo == 0
                                      ? _c(
                                          "span",
                                          { staticClass: "red-font" },
                                          [_vm._v("查看物流")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            item.status == 42 && _vm.$hasMethod("#gorenting")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderGoRent(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("回归租用")]
                                )
                              : _vm._e(),
                            item.status == 44 && _vm.$hasMethod("#confirmR")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmReturn(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("确认归还")]
                                )
                              : _vm._e(),
                            [
                              9, 10, 11, 12, 13, 14, 20, 30, 31, 32, 33, 34, 39,
                              40, 41, 42, 43, 44, 45, 49, 50, 51, 52, 56, 57,
                              58, 71,
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#finish") &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.finishOrder(item.id, item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    完结订单\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            [
                              10, 12, 13, 14, 20, 30, 31, 32, 33, 34, 39, 40,
                              41, 42, 43, 44, 45, 49, 50, 51, 52, 56, 57, 58,
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#finish") &&
                            _vm.merchantId > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.finishOrderMerchant(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    申请完结\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.status == 41 && _vm.$hasMethod("#agree")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.agreeReturn(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("同意归还")]
                                )
                              : _vm._e(),
                            item.status == 41 && _vm.$hasMethod("#refuse")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.refuseReturn(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("拒绝归还")]
                                )
                              : _vm._e(),
                            [2, 3, 9, 10].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#close") &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeOrder(
                                          item.id,
                                          item.status
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭订单")]
                                )
                              : _vm._e(),
                            (item.status == 60 ||
                              (item.status == 1 && item.pay_time > 0)) &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.successOrderReturn(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("订单已退款")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.merchantId == 0
                          ? _c(
                              "div",
                              [
                                [11, 12, 13, 14].indexOf(item.status) > -1
                                  ? _c(
                                      "p",
                                      { staticClass: "btns flex align-center" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "btns-title" },
                                          [_vm._v("退款审核：")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "warning",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRefundOrder(
                                                  item.trade_no
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("去审核")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.status == 39
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "warning",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelwithhold(item.id)
                                          },
                                        },
                                      },
                                      [_vm._v(" 取消代扣 ")]
                                    )
                                  : _vm._e(),
                                item.mayi_sign_status == 2 &&
                                [30, 39].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "danger", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.mayiOrderRecis(item.id)
                                          },
                                        },
                                      },
                                      [_vm._v(" 拒绝解约 ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          _c("custom-page", {
            ref: "customPage",
            attrs: { total: _vm.total },
            on: { getList: _vm.getList },
          }),
        ],
        1
      ),
      _c("check-lease", {
        ref: "checkLease",
        attrs: {
          merchantId: _vm.merchantId,
          "order-id": _vm.selectedId,
          "order-status": _vm.orderStatus,
          editItem: _vm.editItem,
        },
      }),
      _c("viewLeaseAgree", {
        ref: "viewLeaseAgree",
        attrs: {
          merchantId: _vm.merchantId,
          "order-id": _vm.selectedId,
          "order-status": _vm.orderStatus,
          editItem: _vm.editItem,
        },
      }),
      _c("emergencyContact", {
        ref: "emergencyContact",
        attrs: { editItem: _vm.editItem },
      }),
      _c("maYiPayRule", {
        ref: "maYiPayRule",
        attrs: { editItem: _vm.editItem },
      }),
      _c("order-share-list", {
        ref: "orderShareList",
        attrs: { "order-id": _vm.selectedId, "order-no": _vm.orderNo },
      }),
      _c("checklRiskArea", {
        ref: "checklRiskArea",
        attrs: { "edit-item": _vm.editItem },
      }),
      _c("check-risk", {
        ref: "checkRisk",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("check-risk-two", {
        ref: "checkRiskTwo",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("check-risk-three", {
        ref: "checkRiskThree",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("check-risk-zhima", {
        ref: "checkRiskZhima",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("checkRiskJiaohu", {
        ref: "checkRiskJiaohu",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("checkRiskJiaohuZhangqian", {
        ref: "checkRiskJiaohuZhangqian",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("checkRiskConcern", {
        ref: "checkRiskConcern",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId },
      }),
      _c("openZhangqian", {
        ref: "openZhangqian",
        attrs: {
          orderId: _vm.zhanQianInfo.order_id,
          orderInfo: _vm.zhanQianInfo,
        },
        on: { getList: _vm.getOrderList },
      }),
      _c("set-address", {
        ref: "setAddress",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("set-user-info", {
        ref: "setUserInfo",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("cancel-order-equity", {
        ref: "cancelOrderEquity",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("queryOrderEquity", {
        ref: "queryOrderEquity",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("show-area-detail", {
        ref: "showAreaDetail",
        attrs: { "edit-item": _vm.editItem },
      }),
      _c("setDistributionOrder", {
        ref: "setDistributionOrder",
        attrs: {
          "edit-item": _vm.editItem,
          "merchant-list": _vm.merchantListForTrans,
        },
        on: {
          getList: _vm.getList,
          getMerchantList: _vm.getDistributionMerchantListForTrans,
        },
      }),
      _c("setAuditOrder", {
        ref: "setAuditOrder",
        attrs: {
          "edit-item": _vm.editItem,
          "merchant-list": _vm.merchantChildList,
        },
        on: {
          getList: _vm.getList,
          getMerchantChildList: _vm.getMerchantChildList,
        },
      }),
      _c("refushDistributionOrder", {
        ref: "refushDistributionOrder",
        attrs: { "order-id": _vm.selectedId, "order-no": _vm.orderNo },
      }),
      _c("set-device", {
        ref: "setDevice",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("setSetmeal", {
        ref: "setSetmeal",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("setService", {
        ref: "setService",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("set-remark", {
        ref: "setRemark",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList },
      }),
      _c("setRelet", {
        ref: "setRelet",
        attrs: { editItem: _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("check-remark", {
        ref: "checkRemark",
        attrs: { "order-id": _vm.selectedId },
      }),
      _c("check-flow", {
        ref: "checkFlow",
        attrs: { "order-no": _vm.selectedId },
      }),
      _c("delivery", {
        ref: "delivery",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList },
      }),
      _c("receipt", {
        ref: "receipt",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList },
      }),
      _c("auth-info", {
        ref: "authInfo",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("check-bill", {
        ref: "checkBill",
        attrs: { "order-id": _vm.selectedId },
      }),
      _c("check-logistics", {
        ref: "checkLogistics",
        attrs: { "order-id": _vm.selectedId },
      }),
      _c("applyCloseOrder", {
        ref: "applyCloseOrder",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("setRiskReject", {
        ref: "setRiskReject",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("saveCostFun", {
        ref: "saveCostFun",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList },
      }),
      _c("serviceInfo", {
        ref: "serviceInfo",
        attrs: { "edit-item": _vm.editItem },
      }),
      _c("orderInfo", { ref: "orderInfo", attrs: { editItem: _vm.editItem } }),
      _c("contractInfo", {
        ref: "contractInfo",
        attrs: { editItem: _vm.editItem },
      }),
      _c("chooseContract", {
        ref: "chooseContract",
        attrs: { editItem: _vm.editItem },
        on: { getList: _vm.getOrderList, selectedState: _vm.selectedState },
      }),
      _c("requestOver", {
        ref: "requestOver",
        attrs: { item: _vm.selectedItem },
        on: { success: _vm.refresh },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝归还",
            visible: _vm.returnVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.returnVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.returnForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入拒绝原因",
                      type: "text",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.returnForm.checkRefuseReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.returnForm, "checkRefuseReason", $$v)
                      },
                      expression: "returnForm.checkRefuseReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeReturn } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReturnVisible },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("editOutBound", {
        ref: "editOutBound",
        attrs: { "edit-item": _vm.editItem, "merchant-list": _vm.merchantList },
        on: {
          getList: _vm.getList,
          getMerchantList: _vm.getDistributionMerchantList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }